import * as React from "react";
import Helmet from "react-helmet";
import { Clients, CaseStudy, Feature, Form, Gear, Value, Footer } from "../components";
import { SITE } from "../content";
const gearImg = require("../assets/images/pg_lights.jpg");

const seo = {
  title: `Equipment`,
  description: SITE.sections.equipment,
}

// type Props = {};
export default () => {
  return (
    <div className="equipmentPage">
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description}/>
        <meta name="twitter:description" content={seo.description} />
      </Helmet>
      <Feature.Mast title={SITE.sections.equipment} image={gearImg} />
      <Gear.Brands />
      <Gear.List dark={true} />
      <Clients.Min />
      <CaseStudy.Min />
      <Form.Contact />
      <Footer /> 
    </div>
  );
};
